import React, {useEffect, useState, useRef, } from 'react';
import ReactDOM from 'react-dom';
import './style.css';
import {Manager, Reference, Popper} from 'react-popper';
import ClickAway from '@material-ui/core/ClickAwayListener';
import CustomDatePicker from "../../components/datePicker";
import CalendarSvg from './calendar';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import dateFnsFormat from "date-fns/format";
import addDays from 'date-fns/addDays';
import CloseIcon from '@material-ui/icons/Close';
import {placeholder} from "@babel/types";
function renderInPage(){
    return document.querySelector('#root').dataset.place == 'main' ? true : false
}

const DayPicker = (props) => {
    //props
    const {
        class_icon= "icon-place-localizer",
        initData = [],
        callback = () => {
        },
        minDate='',
        defData = '',
        textLabel='',
        textPopper='',
        clearInput,
        id='',
        name='',
        placeholder='',
        disabled = true
    } = props;

    //state
    const [data, setData] = useState(initData);
    const [open, setOpen] = useState(false);
    const [openElem, setOpenElem] = useState(null);
    const [input, setInput] = useState(defData);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
    //ref

    //effect
    useEffect(()=>setData(initData),[initData]);
    //function
    const handleClick = (e) => {

        if(!e.target.closest('.close') && !disabled){
            setOpen(!open);
            setOpenElem(e.target);
        }

    };
    const handleToday = (e) =>{
        callback( dateFnsFormat(addDays(new Date(),1),'dd.MM.yyyy'));
        if(!e.target.closest('.search_form__datePicker_control_desktop') ){
            setOpen(!open);
        }

    };
    const handleTomorow = (e) =>{
        callback( dateFnsFormat(addDays(new Date(),2),'dd.MM.yyyy'));
        if(!e.target.closest('.search_form__datePicker_control_desktop')){
            setOpen(!open);
        }

    };
    const handleClear = (e) =>{
        e.preventDefault();
        callback('');
        setOpen(false);
    };
    const onAway = (e,x) =>{
        if(e.target !== openElem){
            setOpen(false);
        }
    }


    if(!fullScreen){
        return (

            <Manager>
                <Reference>
                    {({ref}) => (
                        <div
                             className={`form-group`}>
                            <label  onClick={handleClick} htmlFor={`search_from__${id}`} >
                                <span className={`icon ${class_icon} mr-1`} ></span>
                                {textLabel}
                            </label>

                            <input autoComplete="off"
                                   ref={ref}
                                   id={`search_from__${id}`}
                                   type="text"
                                   name={id}
                                   readOnly
                                   className="form-control bg-white"
                                   placeholder={textPopper}
                                   value={defData}
                                   onChange={() => {}}
                                   onClick={handleClick}
                            />


                            {
                                (clearInput == false &&  !fullScreen) &&
                                <div className={'search_form__datePicker_control_desktop'}>
                                    <button className={"btn btn-secondary"} onClick={handleToday}>Завтра</button>
                                    <button className={"btn btn-secondary"} onClick={handleTomorow}>Послезавтра</button>
                                </div>

                            }
                        </div>




                    )}
                </Reference>
                {
                    (initData.length > 0) &&
                        ReactDOM.createPortal(
                    <Popper placement="bottom-start" modifiers={{
                        flip: {
                            enabled: false,
                        },
                        preventOverflow: {
                            enabled: true,
                            boundariesElement: 'scrollParent',
                        },
                    }}>
                        {({ref, style, placement}) => (
                            <ClickAway onClickAway={onAway} >
                             <div ref={ref} style={{...style}} className={`${open == false ? "d-none": "d-block"}`} data-placement={placement}>

                                    <div className={'card card-shadow card-border'} style={{overflow:'hidden'}}>

                                        <div className="bg-white">
                                        <div className="search_form__datePicker_content">
                                            {
                                                clearInput &&
                                                <div className="search_form_datePicker_control">
                                                    <button onClick={handleClear} className={"btn btn-secondary"}>Обратный билет не нужен</button>
                                                </div>
                                            }
                                            {

                                            <CustomDatePicker
                                                min = {minDate}
                                                initData={initData}
                                                callback={callback}
                                                setOpen={setOpen}

                                                defData={defData}
                                            />
                                            }
                                        </div>
                                        </div>
                                    </div>

                            </div>
                            </ClickAway>
                        )}
                    </Popper>,document.querySelector('#portal-form'))
                }
            </Manager>

        );
    }
    else{
        return <>
            <div
                 className={'form-group'}>
                <label  onClick={handleClick} htmlFor={`search_from__${id}`}>
                    <span className={`icon ${class_icon} mr-1`} ></span>
                    {textLabel}
                </label>


                    <input autoComplete="off"
                           id={`search_from__${id}`}
                           type="text"
                           name={id}
                           readOnly
                           className="form-control bg-white"
                           placeholder={textPopper}
                           value={defData}
                           //disabled={data.length > 0 && false}
                           onChange={() => {
                           }}
                           onClick={handleClick}

                    />
                    {/*{*/}
                    {/*    (defData.length != 0 && clearInput != false) ?*/}
                    {/*        <div className={'search_form_icon close'}>*/}
                    {/*            <CloseIcon onClick={handleClear}/>*/}
                    {/*        </div>*/}
                    {/*        : clearInput == false &&*/}
                    {/*        <div className={'search_form_icon'} onClick ={handleClick} style={{cursor:'pointer'}}>*/}
                    {/*            <CalendarSvg/>*/}
                    {/*        </div>*/}

                    {/*}*/}




            </div>
        <Dialog open={open} fullScreen={fullScreen}>
            <div className={'search_form__dialog_content'}>
                <div className="search_form__inputModal_inputText">{textPopper}
                    <CloseIcon onClick ={()=>setOpen(!open)}/>
                </div>
                <div className="search_form_datePicker_control">
                    {
                        clearInput &&  <button onClick={handleClear} className={"btn btn-secondary"} style={{maxWidth:'100%',marginBottom:'0.5rem'}}>Обратный билет не нужен</button>
                    }
                    <button className={"btn btn-secondary"} onClick={handleToday} >Завтра</button>
                    <button className={"btn btn-secondary"} onClick={handleTomorow} >Послезавтра</button>
                </div>
                <div className={'search_form__datePicker_container'}>
                <div className="search_form__datePicker_content">
                    {   initData.length > 0 &&
                        <CustomDatePicker
                            initData={initData}
                            callback={callback}
                            min = {minDate}
                            setOpen={setOpen}
                            defData={defData}
                        />
                    }

                </div>

                </div>

            </div>
        </Dialog>
            </>
    }

};

export default DayPicker;