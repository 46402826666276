import React, {useEffect, useMemo, useState, Suspense} from 'react';
//components
import DatePicker from './container/DayPickerPopper';
import Counter from './container/counterPessangers';
import Nights from './container/nights';
import AutoCompleateWithGroup from "./container/autoCompleatWithGroup";
import AutoCompleate from "./container/autoCompleat";
import Spiner from '@material-ui/core/CircularProgress';
import Alert from "./components/alert";
//utiles
import dateFnsFormat from 'date-fns/format';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';
import * as API from './api/index.js';
import {uniqBy, unionBy, uniqWith, isEqual, sortBy, concat} from 'lodash';
import compareAsc from 'date-fns/compareAsc';
import dateFnsParse from 'date-fns/parse';
import addDays from 'date-fns/addDays'
import axios from 'axios';
import qs from 'qs';

//css
import './response.css';
import './style.scss';
//demo data
import demoData from './demoData/demo';
import demoDates from './demoData/dates';
import demoPlace from './demoData/place';

function setSessionStorage(name, data) {
    sessionStorage.setItem(name, JSON.stringify(data));
};

function getSessionStorage(name) {
    return JSON.parse(sessionStorage.getItem(name)) || null;

};

let prevVal = 0;
let renderCount = 0;

function App({nameForm, two_calendar, elem, request_options, lang, type}) {


    const defaultState = {
        selected_placements: null,
        selected_group_placements:null,
        selected_direction:  null,
        selected_tour: null,
        selected_date: '',
        selected_date_to: '',
        selected_pessangers: {
            adults: 1,
            children: 0
        },
        selected_age: {},
        selected_nights: [],
        selected_real_nights: [],
    };
    const [data, setData] = useState({
        direction: [],
        tours: {},
        placements: [],
        placementsGroups:[],
        dates: [],
        error: false
    });
    const [request_body, setBody] = useState(defaultState);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down('xs'));
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const sm = useMediaQuery(theme.breakpoints.down('sm'));

    //methods
    const getDataDirectionAndTour = async () => {
        let result = await API.request(request_options.url_direction, {sessid: request_options.sessid, types:type});

        if (result.error == true || result.error == 'Ошибка запроса') {
            //setData({direction: demoData.direction, tours: demoData.tours, dates: [],placements:demoPlace.placements, placementsGroup: demoPlace.placementsGroup});
        } else {

            const {direction = [], tours = {}, placements = [], placementsGroups=[]} = result;

            setData((d)=>({...d, direction, tours, placements,placementsGroups}));
        }
    };
    const getDates = async (tours_id = []) => {
        setError(false);
        let result = await API.request(request_options.date_url, {sessid: request_options.sessid, tours_id});

        if (result.error == true || result.error == 'Ошибка запроса') {
            setError(result.error);
            if (data.direction.length > 0) {
                let dates = demoDates.dates.filter(item => item.tour_id == tours_id.find(id => id == item.tour_id));
                dates = unionBy(sortBy(dates, ['date', 'price']), 'date');

                    setData(
                        {
                            ...data,
                            dates: dates
                        });

            }
        } else {

            if (result.dates.length > 0) {

                let dates = result.dates.filter(item => item.tour_id == tours_id.find(id => id == item.tour_id));
                dates = unionBy(sortBy(dates, ['date', 'price']), 'date');

                let newDate = dates.map(({date}) => dateFnsParse(date, 'dd.MM.yyyy',new Date())).sort(compareAsc);
                newDate = newDate.map(item=>dateFnsFormat(item,'dd.MM.yyyy'));
                newDate = newDate.map(item=>dates.find(({date})=> date == item));
                newDate = newDate.map(item=>{
                    if(item.nights.length == 0){
                        item.nights = [1,2,3,4,5,6,7,8,9,10,11,12,13,14];
                        item.realNights = [1,2,3,4,5,6,7,8,9,10,11,12,13,14];
                    }
                    return item;
                })
          //      debugger;
                //dates = dates.map(item=>({...item,nights:[...item.nights,15,16,17,Math.random()],realNights:[...item.realNights,15,16,17,Math.random()]}));
                setData(
                    {
                        ...data,
                        dates: newDate
                    });


            }
            else{
                setError(true);
            }
        }
    };
    const getDatesPlacements = async(service_id)=> {
        let {dates} = await API.request(request_options.date_url, {sessid: request_options.sessid, service_id});
        setError(false);
        if(dates.length > 0){
            dates = unionBy(sortBy(dates, ['date', 'price']), 'date');
            if('nights' in dates[0] == false){
                let nights = [];
                for(let i = 1; i<31; i++){
                    nights.push(i);
                }
                dates = dates.map(item=> ({...item, nights:nights}));
            }
            setData((d)=>{
                return {...d, dates: dates}
            });
        }
        else{
            setError(true);
        }
    };

    const getAllTours_id = () => {
        if (data.direction.length > 0) {
            let tours_id = data.direction.map(({tours_id}) => tours_id);
            tours_id = tours_id.reduce((prev, cur, index) => {
                return prev.concat(cur);
            });
            return tours_id;
        }
    };

    //effects
    useEffect(() => {
        getDataDirectionAndTour();
    }, []);

    //const memoDirection = useMemo(()=> JSON.stringify(data.direction), [data.direction]);
    // useEffect(() => {
    //
    //         if (data.direction.length > 0 ) {
    //             let tours_id = data.direction.map(({tours_id}) => tours_id);
    //             tours_id = tours_id.reduce((prev, cur, index) => {
    //                 return prev.concat(cur);
    //             });
    //             if (tours_id && tours_id.length > 0) {
    //                 getDates(tours_id);
    //             }
    //         }
    // }, [request_body.selected_direction]);

    useEffect(() => {
            let direction = data.direction.find(item => request_body.selected_direction == item.id);
            if (direction) {
                request_body.selected_link = direction.link;
                setBody({...request_body});
                getDates(direction.tours_id);
            }
           else if (request_body.selected_direction == 'all') {
                let all_tour_id = data.direction.map(({tours_id}) => tours_id);
                if (all_tour_id.length > 0) {
                    all_tour_id = all_tour_id.reduce((prev, cur, index) => {
                        return prev.concat(cur);
                    });
                    request_body.selected_link = request_body.default_url;
                    setBody({...request_body});
                    getDates(all_tour_id);
                }
            }
    }, [request_body.selected_direction]);

    const memoPlacements = useMemo(()=> JSON.stringify(data.placements), [data.placements]);
    useEffect(()=>{
        if(data.placements.length > 0){
            let place =  data.placements.find(item=> request_body.selected_placements == item.id);
            if (place) {
                request_body.selected_link = place.link;
                setBody({...request_body});
                getDatesPlacements(request_body.selected_placements);
            }
        }
        },
        [memoPlacements]
    );

    //const memoDates = useMemo(()=> JSON.stringify(data.dates), [data.dates]);
    useEffect(() => {

            if (data.dates.length > 0  && request_body.selected_direction != null) {

                const dateArr = data.dates.map(({date}) => dateFnsParse(date, 'dd.MM.yyyy', new Date())).sort(compareAsc);
                const realNights = getNights('realNights',dateArr);
                let date_to = addDays(dateArr[0], realNights[0]);

            //    debugger;
                setBody({
                    ...request_body,
                    selected_date: dateFnsFormat(dateArr[0], 'dd.MM.yyyy'),
                    //selected_date_to: dateFnsFormat(date_to, 'dd.MM.yyyy'),
                    selected_nights: 'nights' in data.dates[0] ? getNights('nights',dateArr)[0] : '',
                    selected_real_nights:  realNights[0] || ''
                })
            }
    }, [data.dates]);


    useEffect(()=>{
        if (request_body.selected_placements !== 'all' && request_body.selected_placements) {
            const selected = data.placements.find(({id})=>request_body.selected_placements == id);
            const {realObjectsIdList = []} = selected;
            getDatesPlacements(realObjectsIdList);
        }

    },[request_body.selected_placements]);

    useEffect(() => {

            let {selected_tour, selected_direction} = request_body;
            if (selected_tour != null && typeof selected_tour != "undefined" && selected_tour != 'all') {
                getDates([selected_tour]);
                //setBody({...request_body});

            } else if (request_body.selected_tour == 'all') {
                if (selected_direction == 'all') {
                    const tours_id = getAllTours_id();
                    if (tours_id != undefined) {
                        if (tours_id.length > 0) {
                            getDates(tours_id);
                            //setBody({...request_body});

                        }
                    }
                } else if (selected_direction != undefined && selected_direction != null) {
                    let direction = data.direction.find(item => request_body.selected_direction == item.id);
                    if (direction != null && typeof direction != "undefined") {
                        //setBody({...request_body});
                        getDates(direction.tours_id);

                    }
                }
            }


    }, [request_body.selected_tour]);

    useEffect(() => {
        const {children} = request_body.selected_pessangers;

        if (children > 0) {
            if (prevVal < children) {
                setBody({
                    ...request_body,
                    selected_age: {...request_body.selected_age, [children]: 0}
                })
            } else {
                delete request_body.selected_age[children + 1];
            }
            prevVal = children;
        } else {
            request_body.selected_age = {};
            setBody({
                ...request_body,
                selected_age: {}
            })
        }
    }, [request_body.selected_pessangers.children]);

    const set_data = key => data => {
        switch (key) {
            case 'selected_direction':
                setBody({
                    ...request_body,
                    [key]: data,
                    selected_tour: null,
                    // selected_date: '',
                    // selected_date_to: '',
                    // selected_nights:[],
                });
                setSessionStorage(nameForm, {...request_body, [key]: data});
                break;
            case 'selected_placements':
                setBody({
                    ...request_body,
                    [key]: data,
                    selected_direction:'all',
                    selected_tour: 'all',
                    selected_date: '',
                    selected_date_to: '',
                    selected_nights:[],
                });
                setSessionStorage(nameForm, {...request_body, [key]: data});
                break;
            case 'selected_tour': {
                setBody({
                    ...request_body,
                    [key]: data,
                    // selected_date: '',
                    // selected_date_to: '',
                    // selected_nights:[]
                });
                setSessionStorage(nameForm, {...request_body, [key]: data});
                break;
            }
            case 'selected_nights':{
                let parsedDate = dateFnsParse(request_body.selected_date, 'dd.MM.yyyy', new Date());
                const nights = getNights('nights');
                const realNights = getNights('realNights');
                const indexSelectedNight = nights.indexOf(parseInt(data));
                parsedDate = dateFnsFormat(addDays(parsedDate, parseInt(realNights[indexSelectedNight])), 'dd.MM.yyyy');
                setBody({
                    ...request_body,
                    [key]: data,
                    selected_real_nights: realNights[indexSelectedNight],
                    selected_date_to: parsedDate
                });
                setSessionStorage(nameForm, {
                    ...request_body,
                    [key]: data,
                    selected_date_to: parsedDate
                });
                break;
            }
            case 'selected_date': {
                if(nameForm != 'transfer'){
                    let parsedDate = dateFnsParse(data, 'dd.MM.yyyy', new Date());
                    const realNights = getNights('realNights',data);
                    parsedDate = dateFnsFormat(addDays(parsedDate, realNights[0]), 'dd.MM.yyyy');
                    setBody({
                        ...request_body,
                        [key]: data,
                        selected_date_to: parsedDate,
                        selected_nights: getNights('nights',data)[0],
                        selected_real_nights:realNights[0]
                    });
                }
                else{
                    let parsedDate = dateFnsParse(data, 'dd.MM.yyyy', new Date());
                    setBody({
                        ...request_body,
                        [key]: data,
                        //selected_date_to: parsedDate,
                        //selected_nights: getNights('nights',data)[0],
                        //selected_real_nights:realNights[0]
                    });
                }


                break;
            }
            case 'selected_age': {
                let buf = request_body.selected_age;
                buf[Object.keys(data)] = data[Object.keys(data)];
                setBody({...request_body, selected_age: buf});
                setSessionStorage(nameForm, {...request_body, [key]: data});
                break;

            }

            default:
                setBody({
                    ...request_body,
                    [key]: data
                });
                setSessionStorage(nameForm, {...request_body, [key]: data});
                break;
        }

        //setSessionStorage(nameForm,{...request_body,[key]:data});

    };

    const find_tour = () => {
        if (request_body.selected_direction != undefined && request_body.selected_direction != null && request_body.selected_direction == 'all') {
            return Object.values(data.tours);
        } else {
            const direction = data.direction.find(item => item.id == request_body.selected_direction);
            if (direction) {
                let {tours_id} = direction;
                tours_id = tours_id.map(id => data.tours[id]);
                return tours_id;
            }
        };
    };

    const sendData = async (e) => {

        e.preventDefault();
        try {
            setLoading(true);
            //await window.sleep(1000);
            API.create_url(request_body, request_options, data, nameForm);
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    };

    const getNights = (key,date =null) => {
        if(!date){
            if(request_body.selected_date){
                const findDate = data.dates.find(item=>{
                    return item.date == request_body.selected_date
                });
                if(typeof findDate != "undefined"){
                    return findDate[key];
                }
                else {
                    return data.dates[0][key];
                }
            }
            else return [];
        }
        else{
                const findDate = data.dates.find(item=>{
                    return item.date == date
                });
                if(typeof findDate != "undefined"){
                    return findDate[key];
                }
                else {
                    return data.dates[0][key];
                }
        }


    };



    return (
        <>
        <div id="booking-form" className="d-flex flex-wrap">
            {
                nameForm != 'placements' ?

                    <>
                        <AutoCompleate

                            class_icon={'icon-place-localizer'}
                            name={'arFilterAutobas_51'}
                            textLabel={lang.direction.label || ''}
                            textPopper={lang.direction.popper || ''}
                            initData={data.direction}
                            defData={request_body.selected_direction}
                            callback={set_data('selected_direction')}
                            textAll={'Все ...'}

                        />
                        <AutoCompleate
                            showAll = {true}
                            class_icon={'icon-map2'}
                            textLabel={lang.tour.label || ''}
                            textPopper={lang.tour.popper || ''}
                            initData={find_tour()}
                            defData={request_body.selected_tour}
                            callback={set_data('selected_tour')}
                            textAll={'Все...'}
                        />
                    </>
                    :
                    <AutoCompleateWithGroup
                        class_icon={'icon-place-localizer'}
                        name={'arFilterAutobas_51'}
                        textLabel={lang.placements.label || ''}
                        textPopper={lang.placements.popper || ''}
                        initData={data.placements}
                        groupData={data.placementsGroups}
                        defData={request_body.selected_placements}
                        callback={set_data('selected_placements')}
                        textAll={'Все...'}
                    />
            }

            <DatePicker
                class_icon={'icon-calendar'}
                initData={data.dates || []}
                textLabel={lang.date.label || ''}
                textPopper={lang.date.popper || ''}
                disabled={error}
                id={'date_from'}
                placeholder={lang.date.label}
                callback={set_data('selected_date')}
                defData={request_body.selected_date}
            />

            {
                two_calendar == true ?
                    nameForm != 'transfer' ?
                    <Nights
                    class_icon={'icon-user'}
                    textLabel={lang.date_to.label}
                    textPopper={lang.date_to.popper}
                    textPlaceholder={lang.date_to.placeholder || ''}
                    callback={set_data('selected_nights')}
                    initData={data.dates.length > 0 ? getNights('nights') : []}
                    defData={request_body.selected_nights ? request_body.selected_nights : getNights('nights')[0] }
                    lang={lang}
                    name={['night']}
                    disabled={request_body.selected_date.length == 0 ? true : false}
                    />
                    :<DatePicker
                            class_icon={'icon-calendar'}
                            initData={[]}
                            textLabel={lang.date_to.label || ''}
                            textPopper={lang.date_to.popper || ''}
                            disabled={error}
                            id={'date_to'}
                            placeholder={lang.date_to.label}
                            callback={set_data('selected_date_to')}
                            defData={request_body.selected_date_to}
                            minDate={request_body.selected_date ? dateFnsParse(request_body.selected_date,'dd.MM.yyyy',new Date()) : new Date()}
                        />
                        : null

            }


            <Counter
                class_icon={'icon-user'}
                textLabel={lang.pessangers.label || ''}
                textPopper={lang.pessangers.popper || ''}
                callback={set_data('selected_pessangers')}
                callback_age={set_data('selected_age')}
                initData={request_body.selected_pessangers}
                defDataAge={request_body.selected_age}
                disabled = {error}
                lang={lang}
                name={['eurotrans[adults]', 'eurotrans[children]', 'eurotrans[social]']}
            />
            <div className="form-group">
                <button   type={'submit'} className={`btn btn-secondary`} onClick={sendData} >
                    {
                        loading ? <Spiner className={"bf_form--spiner"}/> :
                            <>
                                <span className="icon-magnifying-glass mr-2"></span>
                                    {lang.submit.label}</> || <>
                                <span className="icon-magnifying-glass mr-2"></span> Найти
                            </>
                    }
                </button>
            </div>

        </div>
           <Alert isOpen={error} text={lang.error}/>
           </>



    )
}


export default App;
