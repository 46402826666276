import React, {useEffect, useState, useRef} from 'react';
import './style.css';
import {Manager, Reference, Popper} from 'react-popper';
import Counter from '../../components/counter';
import PeopleIcon from './peopleIcon';
import ReactDOM from 'react-dom';
import ClickAway from '@material-ui/core/ClickAwayListener';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Collapse from "@material-ui/core/Collapse";
import Fade from '@material-ui/core/Fade';
import nanoid from 'nanoid';
function renderInPage(){
    return document.querySelector('#root').dataset.place == 'main' ? true : false
}
function sumArr(data){
    return data.reduce((prev,current)=> prev+current)
}

const AgePessangers = (length,callback,defData) =>{
    console.log(defData,'<-');

    let template = [];
    let age = 14;
    for(let i=1; i<=length; i++){
        template.push(<div className="list_age_passangers" key = {nanoid()}>
            <Collapse in={true}>
                    <div className={'list_age_pessangers-item align-items-center'}>
                    Возраст {i}-го Ребенка
                        <select name="age" onChange={(e)=>callback({[i]: e.target.value})} value={defData[i]} className={" w-auto"} >
                                {
                                    (function (age) {
                                        let template = [];
                                        for(let i = 0; i <= age; i++){
                                            template.push(
                                                <option key={nanoid()} value={i}>{i}</option>
                                            )
                                        }
                                        return template;
                                    })(12).map(item=>item)
                                }
                            </select>
                    </div>

            </Collapse>
        </div>)
    }
    return template;
};

const CounterPessagners = (props) => {
    //props
    const {
        class_icon= "icon-place-localizer",
        initData = [],
        callback = () => {
        },
        defDataAge={},
        callback_age = () =>{},
        defData = '',
        textLabel = '',
        textPopper = '',
        id = '',
        name='',
        lang
    } = props;
    //state
    const [data, setData] = useState(initData);
    const [open, setOpen] = useState(false);
    const [input, setInput] = useState(defData);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
    //ref
    //effect
    useEffect(()=>setData(initData),[initData]);

    //function

    const handleClick = (e) => {
        if (e.target.closest('.form-group')) {
            setOpen(!open);
        }
    };
    const handleCountPessangers = key => value => {
        initData[key]=value;
        callback(initData);
    };


    if(!fullScreen) {

        return (

            <Manager>
                <Reference>
                    {({ref}) => (
                        <div ref={ref}
                             onClick={handleClick}
                             className={`form-group`}>
                            <label htmlFor={`search_from__${id}`}>
                                <span className={`icon ${class_icon} mr-1`} ></span>
                                {textLabel}
                            </label>

                            <input autoComplete="off"
                                   id={`search_from__${id}`}
                                   type="text"
                                   name={id}
                                   readOnly
                                   className="form-control bg-white"
                                   placeholder={textPopper}
                                   value={initData.adults + initData.children}
                                   disabled={data.length > 0 && false}
                                   onChange={() => {
                                   }}
                            />


                        </div>
                    )}
                </Reference>
                {
                    open &&
                    ReactDOM.createPortal(
                    <Popper  style={{zIndex: 1000}} placement="bottom-end"  modifiers={{
                        flip: {
                            enabled: false,
                        },
                        preventOverflow: {
                            enabled: true,
                            boundariesElement: 'scrollParent',
                        },
                    }}>
                        {({ref, style, placement}) => (
                            <div ref={ref} style={style} data-placement={placement}>
                                <ClickAway onClickAway={() => setOpen(false)}>
                                    <div className={'card card-shadow card-border'} style={{overflow:'hidden'}}>
                                        <div className="bg-white">
                                        <div className="search_form__counter_content">
                                            <Counter callback={handleCountPessangers('adults')}
                                                     initData={initData.adults}
                                                     name={name[0]}
                                                     min={1}
                                                     max={6}
                                            >
                                                <span>{lang.pessangers.description.adults.label || ''}</span>
                                                <small>{lang.pessangers.description.adults.description || ''}</small>
                                            </Counter>
                                            <Counter callback={handleCountPessangers('children')}
                                                     name={name[1]}
                                                     min={0}
                                                     max={4}
                                                     initData={initData.children}>
                                                <span>{lang.pessangers.description.children.label || ''}</span>
                                                <small>{lang.pessangers.description.children.description || ''}</small>
                                            </Counter>

                                            <Collapse in={initData.children > 0 && true}>
                                               <div style={{marginBottom:'1rem',paddingTop:'1rem', borderTop: '1px solid #eee'}}>
                                                   {
                                                       AgePessangers(initData.children,callback_age,defDataAge).map(item=>item)
                                                   }
                                               </div>
                                            </Collapse>
                                        </div>
                                        </div>
                                    </div>
                                </ClickAway>
                            </div>
                        )}
                    </Popper>,document.querySelector('#portal-form'))
                }
            </Manager>

        );
    }
    else return <>
        <div
             onClick={handleClick}
             className={`form-group`}>
            <label htmlFor={`search_from__${id}`}>
                <span className={`icon ${class_icon} mr-1`}></span>
                {textLabel}
            </label>

            <input autoComplete="off"
                   id={`search_from__${id}`}
                   type="text"
                   name={id}
                   readOnly
                   className="form-control bg-white"
                   placeholder={textPopper}
                   value={sumArr(Object.values(initData))}
                   disabled={data.length > 0 && false}
                   onChange={() => {
                   }}
            />
        </div>
        <Dialog open={open} fullScreen={fullScreen}>
            <div className={'search_form__dialog_content'}>
                <div className="search_form__inputModal_inputText">{textPopper}
                <CloseIcon onClick ={()=>setOpen(!open)}/>
                </div>
                <div className={'search_form__counter_container'}>
                    <div className="search_form__counter_header">{textPopper}</div>
                    <div className="search_form__counter_content">

                            <div className="search_form__counter_content">
                                <Counter callback={handleCountPessangers('adults')}
                                         initData={initData.adults}
                                         name={name[0]}
                                         min={1}
                                         max={6}
                                >
                                    <span>Взрослых</span>
                                    <small>от 12 и старше</small>
                                </Counter>
                                <Counter callback={handleCountPessangers('children')}
                                         name={name[1]}
                                         initData={initData.children}
                                         min={0}
                                         max={4}

                                >
                                    <span>Детей</span>
                                    <small>До 12 лет</small>
                                </Counter>

                                <Collapse in={initData.children > 0 && true}>
                                    <div style={{marginBottom:'1rem',paddingTop:'1rem', borderTop: '1px solid #eee'}}>
                                        {
                                            AgePessangers(initData.children,callback_age,defDataAge).map(item=>item)
                                        }
                                    </div>
                                </Collapse>
                            </div>
                    </div>

                </div>
                <div className="search_form__counter_action">
                    <button  className={"btn btn-secondary"} onClick={()=>setOpen(!open)}>Готово</button>
                </div>
            </div>
        </Dialog>
    </>
};

export default CounterPessagners;