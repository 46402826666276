import React, {useEffect, useRef} from 'react';
import {createPortal} from 'react-dom';

const Alert = ({text, isOpen = false}) => {
    const ref = useRef(null);
    useEffect(()=>{
        if(isOpen != false){
            let $ = window.jQuery;
            $(ref.current).modal('show');
        }

    },[isOpen]);

    return createPortal(
        <div ref={ref} className="modal fade" id="alertModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
             aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content p-0">
                    <div className="alert alert-danger m-0" role="alert">
                        {text}
                    </div>
                </div>
            </div>
        </div>
    ,document.querySelector('#alert-portal'));
};

export default Alert;