import React, { useState, useEffect } from 'react';

import './index.css';
import App from './App';
import { hydrate, render } from "react-dom";



window.sleep = function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
};


window.initMainSearchForm = function initApp(typeID, elem, nameForm, two_calendar, lang, request_options) {
        const rootElement = document.getElementById(elem);
        if(rootElement){
            if (rootElement.hasChildNodes()) {
                hydrate( < App nameForm = { nameForm }
                               two_calendar = { two_calendar }
                               lang = { lang }
                               type = { typeID }
                               elem={elem}
                               request_options = { request_options }
                />, rootElement);
            }
            else {
                render( < App nameForm = { nameForm }
                              two_calendar = { two_calendar }
                              elem={elem}
                              lang = { lang }
                              type = { typeID }
                              request_options = { request_options }
                />, rootElement);
            }
        };

}



            // document.onreadystatechange = function() {
            //     if (document.readyState == "complete") {
            //         window.initMainSearchForm(
            //             [1026],
            //             'placements',
            //             'placements',
            //             true, {
            //                 direction: {
            //                     label: "Направление",
            //                     popper: "Куда вы хотите поехать?"
            //                 },
            //                 placements: {
            //                     label: "Отель",
            //                     popper: "Укажите страну"
            //                 },
            //                 tour: { label: "Тур", popper: "Укажите тур" },
            //                 //tour: {label: "Тур", popper: "Укажите тур"},
            //                 date: { label: "Дата заезда", popper: "Укажите дату заезда" },
            //                 date_to: { label: "Продолжительность", popper: "Укажите количество ночей ", placeholder: 'ночей' },
            //                 error: 'По данному направлению туров нет',
            //                 pessangers: {
            //                     label: "Гостей",
            //                     popper: "Укажите количество гостей",
            //                     description: {
            //                         adults: { label: "Взрослых", description: "от 12 лет" },
            //                         children: { label: "Детей", description: "до 12 лет" }
            //                     }
            //                 },
            //                 submit: {
            //                     label: "Найти"
            //                 },
            //             }, {
            //                 default_url: "/hotels/?",
            //                 date_url_param: "travelbooking[date_from]",
            //                 date_to_url_param: "travelbooking[date_to]",
            //                 adults_url_param: "travelbooking[adults]",
            //                 children_url_param: "travelbooking[children]",
            //                 children_age_url_param: "travelbooking[children_age][]",
            //                 url_direction: "http://trakt.travelsoft.by/local/components/travelsoft/booking.search_form/templates/placements/ajax/placements.php",
            //                 date_url: "http://trakt.travelsoft.by/local/components/travelsoft/booking.search_form/templates/placements/ajax/dates.php",
            //                 sessid: 'null',
            //             });
            //         window.initMainSearchForm(
            //             [1078],
            //             'sanatorum',
            //             'placements',
            //             true, {
            //                 direction: {
            //                     label: "Направление",
            //                     popper: "Укажите область"
            //                 },
            //                 placements: {
            //                     label: "Санаторий",
            //                     popper: "Укажите область"
            //                 },
            //                 tour: { label: "Тур", popper: "Укажите тур" },
            //                 //tour: {label: "Тур", popper: "Укажите тур"},
            //                 date: { label: "Дата заезда", popper: "Укажите дату заезда" },
            //                 date_to: { label: "Продолжительность", popper: "Укажите количество ночей ", placeholder: 'ночей' },
            //                 error: 'По данному направлению туров нет',
            //                 pessangers: {
            //                     label: "Гостей",
            //                     popper: "Укажите количество гостей",
            //                     description: {
            //                         adults: { label: "Взрослых", description: "от 12 лет" },
            //                         children: { label: "Детей", description: "до 12 лет" }
            //                     }
            //                 },
            //                 submit: {
            //                     label: "Найти"
            //                 },
            //             }, {
            //                 default_url: "/hotels/?",
            //                 date_url_param: "travelbooking[date_from]",
            //                 date_to_url_param: "travelbooking[date_to]",
            //                 adults_url_param: "travelbooking[adults]",
            //                 children_url_param: "travelbooking[children]",
            //                 children_age_url_param: "travelbooking[children_age][]",
            //                 url_direction: "http://trakt.travelsoft.by/local/components/travelsoft/booking.search_form/templates/placements/ajax/placements.php",
            //                 date_url: "http://trakt.travelsoft.by/local/components/travelsoft/booking.search_form/templates/placements/ajax/dates.php",
            //                 sessid: 'null',
            //             });
            //
            //         window.initMainSearchForm(
            //             [1062],
            //             'excursiontour',
            //             'excursion',
            //             false, {
            //                 direction: {
            //                     label: "Направление",
            //                     popper: "Куда вы хотите поехать?"
            //                 },
            //                 tour: { label: "Тур", popper: "Укажите тур" },
            //                 date: { label: "Начало тура", popper: "Укажите дату выезда" },
            //                 date_to: { label: "Конец тура", popper: "Укажите дату выезда" },
            //                 error: 'По данному направлению туров нет',
            //                 pessangers: {
            //                     label: "Кто едет",
            //                     popper: "Укажите количество пассажиров",
            //                     description: {
            //                         adults: { label: "Взрослых", description: "от 12 лет" },
            //                         children: { label: "Детей", description: "до 12 лет" }
            //                     }
            //                 },
            //                 submit: { label: "Найти" },
            //             }, {
            //                 default_url: "/transfer/?",
            //                 date_url_param: "travelbooking[date_from]",
            //                 date_from: "travelbooking[date_to]",
            //                 date_to: "travelbooking[adults]",
            //                 children_url_param: "travelbooking[children]",
            //                 children_age_url_param: "travelbooking[children_age][]",
            //                 url_direction: "http://trakt.travelsoft.by/local/components/travelsoft/booking.search_form/templates/excursiontours/ajax/citiesDirections.php",
            //                 date_url: "http://trakt.travelsoft.by/local/components/travelsoft/booking.search_form/templates/excursiontours/ajax/dates.php",
            //             });
            //         window.initMainSearchForm(
            //             [204],
            //             'excursion',
            //             'excursion',
            //             false, {
            //                 direction: {
            //                     label: "Направление",
            //                     popper: "Куда вы хотите поехать?"
            //                 },
            //                 tour: { label: "Тур", popper: "Укажите тур" },
            //                 date: { label: "Начало тура", popper: "Укажите дату выезда" },
            //                 date_to: { label: "Конец тура", popper: "Укажите дату выезда" },
            //                 error: 'По данному направлению туров нет',
            //                 pessangers: {
            //                     label: "Кто едет",
            //                     popper: "Укажите количество пассажиров",
            //                     description: {
            //                         adults: { label: "Взрослых", description: "от 12 лет" },
            //                         children: { label: "Детей", description: "до 12 лет" }
            //                     }
            //                 },
            //                 submit: { label: "Найти" },
            //             }, {
            //                 default_url: "/transfer/?",
            //                 direction_from: 'travelbooking[city_from_id]',
            //                 direction_to: 'travelbooking[city_from_id]',
            //                 date_url_param: "travelbooking[date_from]",
            //                 date_from: "travelbooking[date_to]",
            //                 date_to: "travelbooking[adults]",
            //                 children_url_param: "travelbooking[children]",
            //                 children_age_url_param: "travelbooking[children_age][]",
            //                 url_direction: "http://trakt.travelsoft.by/local/components/travelsoft/booking.search_form/templates/excursiontours/ajax/citiesDirections.php",
            //                 date_url: "http://trakt.travelsoft.by/local/components/travelsoft/booking.search_form/templates/excursiontours/ajax/dates.php",
            //             });
            //     }
            // }