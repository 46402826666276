import axios, { post } from 'axios';
import qs, { stringify } from 'qs';
import { root_elem } from "../const";
import dateFnsFormat from "date-fns/format";

const emptyFn = () => {};

export const request = async(url = '', request_body = {}, effect = emptyFn) => {
    try {

        console.log(request_body);
        let result = await post(url, stringify(request_body));
        return result.data;

    } catch (e) {

        return { error: 'Ошибка запроса' };
    }
};

export function setStorage(key, data) {
    sessionStorage.setItem(`eurotrans_form[${key}]`, JSON.stringify(data))
}

export function getStorage(key, defData) {
    return sessionStorage.getItem(key) ? JSON.parse(sessionStorage.getItem(key)) : defData
}

export function renderInPage() {
    return root_elem.dataset.place == 'main' ? true : false
}

function UrlOption(name, obj, data) {
    if (name && data) {
        obj[name] = data;
    }
    return obj;
}
export function create_url(state, request_options, data, nameForm) {

    let selected_link = '/';
    const {
        selected_placements = null,
            selected_direction = null,
            selected_tour = null,
            selected_date,
            selected_date_to,
            selected_pessangers: { adults, children },
            selected_age,
            selected_real_nights = null,
            selected_nights

    } = state;

    const {
        direction_from = null,
            date_url_param = null,
            date_to_url_param = null,
            adults_url_param = null,
            children_url_param = null,
    } = request_options;

    const { tours = null, direction = null, placements = null } = data;

    let url = {};

    UrlOption(direction_from, url, selected_direction);
    UrlOption(date_url_param, url, selected_date.length > 0 ? selected_date : null);
    UrlOption(date_to_url_param, url, selected_date_to.length > 0 ? selected_date_to : null);
    UrlOption(adults_url_param, url, adults);
    UrlOption(children_url_param, url, children);
    url = {...url, selected_real_nights, selected_nights }


    url = qs.stringify(url);
    Object.values(selected_age).forEach((item, index) => {
        url += `&${request_options.children_age_url_param}=${item}`
    });


    if (nameForm !== 'placements') {
        if (selected_tour && selected_tour != 'all') {
            selected_link = tours[selected_tour].link;
        } else if (selected_direction && selected_direction != 'all') {
            selected_link = direction.find(item => item.id == selected_direction).link;
        } else {
            selected_link = request_options.default_url;
        }
    } else {
        if (selected_placements && selected_placements != 'all') {
            selected_link = `${placements.find(item=> item.id == selected_placements).link}?`;
        } else {
            selected_link = request_options.default_url;
        }
    }


    selected_link = `${selected_link}${url}`;

    window.location.href = selected_link;


}

export function revers(key1, key2, data) {
    let buf = data[key1];
    data[key1] = data[key2];
    data[key2] = buf;
    return {...data };
}

export function updateKeyName(str, obj) {
    let buf = {};
    for (let key in obj) {
        buf[`${str}[${key}]`] = obj[key];
    }
    return buf;
}

export async function getDataUrl(url, body, callback) {
    try {
        let result = await axios.post(url, qs.stringify(body));
        return result.data;
    } catch (error) {
        console.log(error);
    }
}

export async function getDataUrlMiddleware(url, body, middleWare, callback) {
    try {
        let result = await axios.post(url, qs.stringify(body));
        return callback(middleWare(result.data));
    } catch (error) {
        console.log(error);
    }
}

export function disable(data1, data2) {
    return (data1.length > 0 && data2.length > 0) && true
}

export function regReplace(data, callback) {
    return data.map(item => {
        let re = /([^\)]+)\((.*)\)/;
        let point = item.name.match(re);
        if (point !== null) {
            item.point = point[2];
            item.shortName = point[1];
        } else {
            item.point = null;
            item.shortName = null;
        }
        return item;
    });
}

export function getCoords(elem) {
    let box = elem.getBoundingClientRect();

    return {
        top: box.top + window.pageYOffset,
        left: box.left + window.pageXOffset
    };
}